import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Container,
  Grid,
  HStack,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CalendarComponent from "./calendarComponent";
import ExpenseCard from "./expenseCard";
import GrowthPlanCard from "./growthPlanCard";
import PerformanceReview from "./performanceReview";
import ProfitCard from "./profitCard";
import ExpenseBreakdown from "./expense_breakdown";
import { Menu } from "react-feather";
import { customCurrencyFormat, colorFormat, colorExpenseFormat } from "../../utils";

const ResourceCard = ({ title, description, listing, column }) => {
  return (
    <Stack
      width="100%"
      align="center"
      gridColumn={column}
      p="15px 10px"
      position={"relative"}
    >
      <Card width="100%" height="115px" borderRadius="20px">
        <CardBody textAlign={"center"}>
          <Box p={"4px 0 0 0"}>
            <Text fontSize={"x-large"} fontWeight={600}>
              {title}
            </Text>
            <Text>{description}.</Text>
          </Box>
        </CardBody>
      </Card>

      <Card
        position="absolute"
        top="-15px"
        left="50%"
        transform="translateX(-50%)"
        width="50px"
        height="50px"
        borderRadius="100%"
        bg="#536DEC"
      >
        <CardBody p={"10px 0 0 0"}>
          <Text textAlign="center" fontSize={"20px"} color="white">
            {listing}
          </Text>
        </CardBody>
      </Card>
    </Stack>
  );
};

const ResourceOverview = ({ resourceOverviewData }) => {
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  return (
    <Stack p={"20px 0 0 0"}>
      {resourceOverviewData ? (
        resourceOverviewData.first_recommended_resource &&
        resourceOverviewData.second_recommended_resource &&
        resourceOverviewData.third_recommended_resource ? (
          !isMobile ? (
            <SimpleGrid
              templateColumns="1fr 1fr 1fr"
              spacing={2}
              p="5px"
              alignItems="center"
              justifyItems="center"
            >
              <ResourceCard
                title={resourceOverviewData.first_recommended_resource}
                description={"Lorum Ipsum"}
                listing={1}
                column={1}
              />
              <ResourceCard
                title={resourceOverviewData.second_recommended_resource}
                description={"Lorum Ipsum"}
                listing={2}
                column={2}
              />
              <ResourceCard
                title={resourceOverviewData.third_recommended_resource}
                description={"Lorum Ipsum"}
                listing={3}
                column={3}
              />
            </SimpleGrid>
          ) : (
            <VStack>
              <ResourceCard
                title={resourceOverviewData.first_recommended_resource}
                description={"Lorum Ipsum"}
                listing={1}
                column={1}
              />
              <ResourceCard
                title={resourceOverviewData.second_recommended_resource}
                description={"Lorum Ipsum"}
                listing={2}
                column={2}
              />
              <ResourceCard
                title={resourceOverviewData.third_recommended_resource}
                description={"Lorum Ipsum"}
                listing={3}
                column={3}
              />
            </VStack>
          )
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {resourceOverviewData ? (
        resourceOverviewData.first_recommended_resource &&
        resourceOverviewData.second_recommended_resource &&
        !resourceOverviewData.third_recommended_resource ? (
          <SimpleGrid
            templateColumns="1fr 1fr"
            spacing={2}
            p="5px"
            alignItems="center"
            justifyItems="center"
          >
            <ResourceCard
              title={resourceOverviewData.first_recommended_resource}
              description={"Lorum Ipsum"}
              listing={1}
              column={1}
            />
            <ResourceCard
              title={resourceOverviewData.second_recommended_resource}
              description={"Lorum Ipsum"}
              listing={2}
              column={2}
            />
          </SimpleGrid>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {resourceOverviewData ? (
        resourceOverviewData.first_recommended_resource &&
        !resourceOverviewData.second_recommended_resource &&
        !resourceOverviewData.third_recommended_resource ? (
          <SimpleGrid
            templateColumns="1fr 1fr 1fr"
            spacing={2}
            p="5px"
            alignItems="center"
            justifyItems="center"
          >
            <ResourceCard
              title={resourceOverviewData.first_recommended_resource}
              description={"Lorum Ipsum"}
              listing={1}
              column={2}
            />
          </SimpleGrid>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      <Stack width="100%" align="center" p="0px 5px 10px 5px">
        {resourceOverviewData ? (
          resourceOverviewData.performance_overview ? (
            <Card width="100%" height="150px" borderRadius="20px">
              <CardBody textAlign={"center"}>
                <Box>
                  <Text fontSize={"x-large"} lineHeight={"10px"}>
                    Performance Overview
                  </Text>
                  <Stack
                    as="span"
                    display="inline-block"
                    borderTop="2px solid #536DEC"
                    width="150px"
                    textAlign="center"
                  ></Stack>
                </Box>
                <Text>{resourceOverviewData.performance_overview}</Text>
              </CardBody>
            </Card>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
};

const GrowthPlanContent = ({
  customer_id,
  date,
  trigger,
  triggerSetter,
  setNavIndex,
}) => {
  const formattedDate = date.toISOString().split("T")[0];
  const [growthPlanData, setGrowthPlanData] = useState({
    income: "0.00",
    income_forecast: "0.00",
    income_forecast_percentage: "0.00",
    cash_on_hand: "0.00",
    last_cash_on_hand: "0.00",
    last_cash_on_hand_percentage: "0.00",
    expense: "0.00",
    expense_forecast: "0.00",
    expense_forecast_percentage: "0.00",
    equity: "0.00",
    last_equity: "0.00",
    last_equity_percentage: "0.00",
    profit: "0.00",
    profit_percentage: "0.00",
    team_percentage: "0.00",
    marketing_percentage: "0.00",
    overhead_percentage: "0.00",
    cogs_percentage: "0.00",
    team: "0.00",
    marketing: "0.00",
    overhead: "0.00",
    cogs: "0.00",
  });

  const [resourceOverview, setResourceOverview] = useState({
    first_recommended_resource: null,
    second_recommended_resource: null,
    third_recommended_resource: null,
    performance_overview: null,
  });

  const [pLData, setPLData] = useState({
    overhead_pl: "0.00",
    team_pl: "0.00",
    profit_pl: "0.00",
    marketing_pl: "0.00",
    cogs_pl: "0.00",
  });
  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshGrowthPlanData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/growthplan/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal, // Attach the signal for cancellation
            }
          );
          if (isActive) {
            setGrowthPlanData(res.data); // Update state only if the effect is active
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshGrowthPlanData(customer_id);

    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshResourceOverview = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/resourceperformanceview/${formattedDate}/`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal, // Attach the signal for cancellation
            }
          );
          if (isActive) {
            setResourceOverview(res.data); // Update state only if the effect is active
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshResourceOverview(customer_id);

    // Cleanup to handle stale state and abort ongoing requests
    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);

  useEffect(() => {
    const controller = new AbortController(); // AbortController for request cancellation
    let isActive = true; // Flag to track active state

    const refreshPLData = async (id) => {
      if (id) {
        try {
          const res = await axios.get(
            `/api/v2/customers/${id}/plvalues/${formattedDate}`,
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
              signal: controller.signal, // Attach the signal for cancellation
            }
          );
          if (isActive) {
            setPLData(res.data); // Update state only if the effect is active
          }
        } catch (err) {
          if (err?.response) {
            console.log(err);
          }
        }
      }
    };

    refreshPLData(customer_id);
    triggerSetter(false); // Reset the trigger state

    // Cleanup to handle stale state and abort ongoing requests
    return () => {
      isActive = false; // Mark effect as inactive
      controller.abort(); // Cancel the Axios request
    };
  }, [customer_id, trigger, triggerSetter, formattedDate]);


  return (
    <Stack mt={isMobile ? "140px" : "75px"} p={isMobile ? "10px 10px" : "0px"}>
      {!isMobile ? (
        <Grid
          templateColumns="2fr 1fr 2fr"
          spacing={2}
          p="25px 10px 10px 5px"
          alignItems="center"
          justifyItems="center"
        >
          <Stack
            width="100%"
            align="center"
            gridColumn={1}
            gridRow={1}
            p="15px 10px"
          >
            <GrowthPlanCard
              leftTitle="Total Income"
              rightTitle="Forecast"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.income : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.income_forecast : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.income_forecast_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.income_forecast_percentage
                  : "0.00"
              )}
            />
          </Stack>
          <Stack
            width="100%"
            align="center"
            gridColumn={1}
            gridRow={2}
            p="15px 10px"
          >
            <GrowthPlanCard
              leftTitle="Cash on Hand"
              rightTitle="Last Month"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.cash_on_hand : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.last_cash_on_hand : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.last_cash_on_hand_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.last_cash_on_hand_percentage
                  : "0.00"
              )}
            />
          </Stack>
          <Stack align="center" gridColumn={2} gridRow="span 2" width="320px">
            <ProfitCard
              profit={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit : 0.0
              )}
              profitPercentage={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.profit_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.profit_pl : "0.00",
                growthPlanData ? growthPlanData.profit_percentage : "0.00",
                true,
                true
              )}
              status={true}
              data={growthPlanData ? growthPlanData : {}}
            />
          </Stack>
          <Stack
            width="100%"
            align="center"
            gridColumn={3}
            gridRow={1}
            p="15px 10px"
          >
            <GrowthPlanCard
              leftTitle="Total Expenses"
              rightTitle="Forecast"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.expense : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.expense_forecast : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.expense_forecast_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.expense_forecast_percentage
                  : "0.00",
                true
              )}
            />
          </Stack>
          <Stack
            width="100%"
            align="center"
            gridColumn={3}
            gridRow={2}
            p="15px 10px"
          >
            <GrowthPlanCard
              leftTitle="Equity"
              rightTitle="Last Month"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.equity : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.last_equity : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData ? growthPlanData.last_equity_percentage : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData ? growthPlanData.last_equity_percentage : "0.00"
              )}
            />
          </Stack>
        </Grid>
      ) : (
        <VStack align="center" justify="center" w="100%" h="100%">
          <Stack width="100%" align="center" mb={"15px"}>
            <ProfitCard
              profit={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit : 0.0
              )}
              profitPercentage={customCurrencyFormat(
                growthPlanData ? growthPlanData.profit_percentage : 0.0,
                true
              )}
              perfectPL={customCurrencyFormat(
                pLData ? pLData.profit_pl : 0.0,
                true
              )}
              color={colorExpenseFormat(
                pLData ? pLData.profit_pl : "0.00",
                growthPlanData ? growthPlanData.profit_percentage : "0.00",
                true,
                true
              )}
              status={true}
              data={growthPlanData ? growthPlanData : {}}
            />
          </Stack>
          <Stack width="100%" align="center" p="5px 10px">
            <GrowthPlanCard
              leftTitle="Total Income"
              rightTitle="Forecast"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.income : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.income_forecast : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.income_forecast_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.income_forecast_percentage
                  : "0.00"
              )}
            />
          </Stack>
          <Stack
            width="100%"
            align="center"
            gridColumn={3}
            gridRow={1}
            p="5px 10px"
          >
            <GrowthPlanCard
              leftTitle="Total Expenses"
              rightTitle="Forecast"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.expense : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.expense_forecast : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.expense_forecast_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.expense_forecast_percentage
                  : "0.00",
                true
              )}
            />
          </Stack>
          <Stack width="100%" align="center" p="5px 10px">
            <GrowthPlanCard
              leftTitle="Cash on Hand"
              rightTitle="Last Month"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.cash_on_hand : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.last_cash_on_hand : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData
                    ? growthPlanData.last_cash_on_hand_percentage
                    : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData
                  ? growthPlanData.last_cash_on_hand_percentage
                  : "0.00"
              )}
            />
          </Stack>
          <Stack width="100%" align="center" p="5px 10px">
            <GrowthPlanCard
              leftTitle="Equity"
              rightTitle="Last Month"
              leftAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.equity : 0.0}`
              )}
              rightAmount={customCurrencyFormat(
                `${growthPlanData ? growthPlanData.last_equity : 0.0}`
              )}
              rightPercentage={customCurrencyFormat(
                `${
                  growthPlanData ? growthPlanData.last_equity_percentage : 0.0
                }`,
                true
              )}
              cardHeight="120px"
              cardWidth="100%"
              color={colorFormat(
                growthPlanData ? growthPlanData.last_equity_percentage : "0.00"
              )}
            />
          </Stack>
        </VStack>
      )}
      {!isMobile ? (
        <SimpleGrid
          templateColumns="1fr 1fr 1fr 1fr"
          spacing={2}
          p="5px"
          alignItems="center"
          justifyItems="center"
        >
          <ExpenseCard
            title="Team"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.team_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.team : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.team_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.team_pl : "0.00",
              growthPlanData ? growthPlanData.team_percentage : "0.00"
            )}
          />
          <ExpenseCard
            title="Marketing"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.marketing_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.marketing : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.marketing_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.marketing_pl : "0.00",
              growthPlanData ? growthPlanData.marketing_percentage : "0.00"
            )}
          />
          <ExpenseCard
            title="Overheads"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.overhead_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.overhead : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.overhead_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.overhead_pl : "0.00",
              growthPlanData ? growthPlanData.overhead_percentage : "0.00"
            )}
          />
          <ExpenseCard
            title="Products (COGS)"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cogs_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cogs : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.cogs_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.cogs_pl : "0.00",
              growthPlanData ? growthPlanData.cogs_percentage : "0.00"
            )}
          />
        </SimpleGrid>
      ) : (
        <VStack margin={"15px 0px"}>
          <ExpenseCard
            title="Team"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.team_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.team : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.team_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.team_pl : "0.00",
              growthPlanData ? growthPlanData.team_percentage : "0.00"
            )}
          />
          <ExpenseCard
            title="Marketing"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.marketing_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.marketing : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.marketing_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.marketing_pl : "0.00",
              growthPlanData ? growthPlanData.marketing_percentage : "0.00"
            )}
          />
          <ExpenseCard
            title="Overheads"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.overhead_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.overhead : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.overhead_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.overhead_pl : "0.00",
              growthPlanData ? growthPlanData.overhead_percentage : "0.00"
            )}
          />
          <ExpenseCard
            title="Products (COGS)"
            percentage={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cogs_percentage : 0.0}`,
              true
            )}
            total={customCurrencyFormat(
              `${growthPlanData ? growthPlanData.cogs : 0.0}`
            )}
            perfectPL={customCurrencyFormat(
              pLData ? pLData.cogs_pl : 0.0,
              true
            )}
            color={colorExpenseFormat(
              pLData ? pLData.cogs_pl : "0.00",
              growthPlanData ? growthPlanData.cogs_percentage : "0.00"
            )}
          />
        </VStack>
      )}
      <ResourceOverview resourceOverviewData={resourceOverview} />
    </Stack>
  );
};

export const GrowthPlan = ({
  customer_id,
  dateFromNav = null,
  setDateFromNav = null,
  performanceToggle = false,
  setNavIndex = null,
  setNavVisible = null,
}) => {
  const [tab, setTab] = useState(
    performanceToggle ? "performanceReview" : "growthPlan"
  );
  const [triggerRefesh, setTriggerRefresh] = useState(true);
  const [expenseBreakdown, setExpenseBreakdown] = useState(false);
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const [selectedPeriod, setSelectedPeriod] = useState("month");
  const [isMobile] = useMediaQuery("(max-width: 1280px)");

  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
  lastDayOfPreviousMonth.setDate(0);

  const [dashDate, setDashDate] = useState(
    dateFromNav ? dateFromNav : lastDayOfPreviousMonth
  );
  const [breakdownData, setBreakdownData] = useState({ title: "", type: "" });
  const updateDate = (newDate) => {
    setDashDate(new Date(newDate));
    if (setDateFromNav) {
      setDateFromNav(newDate);
    }
  };

  return (
    <Container
      maxW="100%"
      bg="#F4F4F2"
      minH={"100vh"}
      p="0px"
      overflowY={setNavIndex ? "hidden" : "auto"}
      overflowX={"hidden"}
      position={"relative"}
    >
      {isMobile ? (
        <Card
          position="fixed"
          zIndex={302}
          borderRadius={0}
          h="75px"
          border="1px solid #e0e0e0"
          width="100%"
        >
          <HStack
            justifyContent="space-between"
            alignItems="center"
            h={"100%"}
            w="100%"
            px={4}
          >
            <Button
              bg="white"
              _hover={{ color: "white" }}
              _active={{ color: "white" }}
              w="50px"
              h="50px"
              p={2}
              onClick={() => setNavVisible && setNavVisible(true)}
            >
              <Menu style={{ width: "50px", height: "100%" }} color="black" />
            </Button>
            {!expenseBreakdown ? (
              <CalendarComponent
                callbackFunctionDate={updateDate}
                triggerSetter={setTriggerRefresh}
                date={dashDate}
              />
            ) : (
              <ButtonGroup
                isAttached
                variant="outline"
                width={"70%"}
                justifyContent={"right"}
              >
                <Button
                  fontSize={"15px"}
                  _hover={{
                    background: "initial",
                    boxShadow: "none",
                    opacity: "100%",
                    backgroundColor:
                      selectedPeriod === "month" ? "#536DEC" : "white",
                  }}
                  backgroundColor={
                    selectedPeriod === "month" ? "#536DEC" : "white"
                  }
                  textColor={selectedPeriod === "month" ? "white" : "#536DEC"}
                  onClick={() => setSelectedPeriod("month")}
                >
                  Current Month
                </Button>
                <Button
                  fontSize={"15px"}
                  _hover={{
                    background: "initial",
                    boxShadow: "none",
                    opacity: "100%%",
                    backgroundColor:
                      selectedPeriod === "year" ? "#536DEC" : "white",
                  }}
                  backgroundColor={
                    selectedPeriod === "year" ? "#536DEC" : "white"
                  }
                  textColor={selectedPeriod === "year" ? "white" : "#536DEC"}
                  onClick={() => setSelectedPeriod("year")}
                >
                  Year to Date
                </Button>
              </ButtonGroup>
            )}
          </HStack>
        </Card>
      ) : (
        <></>
      )}
      {!expenseBreakdown ? (
        <>
          <Card
            position={"fixed"}
            zIndex={301}
            borderRadius={0}
            mt={isMobile ? "75px" : "0px"}
            h={isMobile ? "50px" : "75px"}
            border="1px solid #e0e0e0"
            width={isMobile ? "100%" : "auto"}
            marginBottom={100}
          >
            <Tabs index={tab === "performanceReview" ? 1 : 0}>
              {!isMobile ? (
                <Grid
                  templateColumns="1fr 2fr 9fr"
                  width="100%"
                  align="center"
                  p="25px 20px 0px"
                >
                  <TabList>
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("growthPlan");
                        setNavIndex ? setNavIndex(0) : (setNavIndex = null);
                      }}
                      width="90px"
                      h="50px"
                      p={0}
                    >
                      <Text fontWeight="bold">Growth Plan</Text>
                    </Tab>
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("performanceReview");
                        setNavIndex ? setNavIndex(1) : (setNavIndex = null);
                      }}
                      width="170px"
                      h="50px"
                      p={0}
                      whiteSpace="nowrap"
                    >
                      <Text fontWeight="bold">Performance Review</Text>
                    </Tab>
                  </TabList>
                  <Stack gridColumn={9} textAlign="right">
                    {tab === "growthPlan" && (
                      <CalendarComponent
                        callbackFunctionDate={updateDate}
                        triggerSetter={setTriggerRefresh}
                        date={dashDate}
                      />
                    )}
                  </Stack>
                </Grid>
              ) : (
                <HStack width="100%" align="center" justify="flex-start">
                  <TabList
                    display="flex"
                    justifyContent="flex-start"
                    paddingLeft={2}
                    paddingRight={2}
                    w="100%"
                  >
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("growthPlan");
                        setNavIndex ? setNavIndex(0) : (setNavIndex = null);
                      }}
                      h="50px"
                      p="2px 4px"
                      whiteSpace="nowrap" // Prevents text from wrapping
                    >
                      <Text fontWeight="bold" textAlign="center">
                        Growth Plan
                      </Text>
                    </Tab>
                    <Tab
                      _focus={{
                        outline: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      _selected={{
                        borderBottom: "3px solid #536DEC",
                        color: "#536DEC",
                      }}
                      _active={{ background: "none" }}
                      cursor="pointer"
                      onClick={() => {
                        setTab("performanceReview");
                        setNavIndex ? setNavIndex(1) : (setNavIndex = null);
                      }}
                      minWidth="170px" // Ensures minimum width for Performance Review
                      h="50px"
                      p="2px 4px"
                      whiteSpace="nowrap" // Prevents text from wrapping
                    >
                      <Text fontWeight="bold" textAlign="center">
                        Performance Review
                      </Text>
                    </Tab>
                  </TabList>
                </HStack>
              )}
            </Tabs>
          </Card>

          {tab === "growthPlan" ? (
            <GrowthPlanContent
              customer_id={customer_id}
              date={dashDate}
              trigger={triggerRefesh}
              triggerSetter={setTriggerRefresh}
              setNavIndex={setNavIndex}
            />
          ) : (
            <PerformanceReview
              customer_id={customer_id}
              dashDate={dashDate}
              trigger={triggerRefesh}
              triggerSetter={setTriggerRefresh}
              updateDate={updateDate}
              triggerExpenseBreakdown={setExpenseBreakdown}
              setBreakdownData={setBreakdownData}
            />
          )}
        </>
      ) : (
        <ExpenseBreakdown
          customer_id={customer_id}
          dashDate={dashDate}
          setExpenseBreakdown={setExpenseBreakdown}
          setTab={setTab}
          title={breakdownData.title}
          type={breakdownData.type}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
      )}
    </Container>
  );
};
