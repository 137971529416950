import React, { useState, useEffect, useRef } from "react";
import { Chart } from "chart.js";
import {
  Box,
  Text,
  Tooltip as ChakraTooltip,
  HStack,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";
import { customCurrencyFormat } from "../../utils";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController
);

const MetricGraph = ({ metricGraphData }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 1280px)");
  const tooltipPosition = useRef({ x: 0, y: 0 });
  const tooltipData = useRef(null);

  useEffect(() => {
    const canvas = document.getElementById("myChart");
    const ctx = canvas.getContext("2d");

    const createTooltip = (tooltipModel) => {
      if (!tooltipModel.opacity) {
        setIsTooltipVisible(false);
        return;
      }

      const dataPoints = tooltipModel.dataPoints;
      if (!dataPoints || dataPoints.length === 0) {
        setIsTooltipVisible(false);
        return;
      }

      // Get the closest point from the hover interaction
      const point = dataPoints.reduce((closestPoint, currentPoint) => {
        if (currentPoint.element) {
          const currentDistance = Math.sqrt(
            Math.pow(currentPoint.element.x - tooltipModel.x, 2) + Math.pow(currentPoint.element.y - tooltipModel.y, 2)
          );
          const closestDistance = Math.sqrt(
            Math.pow(closestPoint.element.x - tooltipModel.x, 2) + Math.pow(closestPoint.element.y - tooltipModel.y, 2)
          );

          return currentDistance < closestDistance ? currentPoint : closestPoint;
        }
        return closestPoint;
      });

      if (!point) {
        setIsTooltipVisible(false);
        return;
      }

      const { datasetIndex, dataIndex } = point;
      const chart = tooltipModel.chart;
      const datasetMeta = chart.getDatasetMeta(datasetIndex);

      // Ensure we're working with the correct dataset type (e.g., line)
      if (datasetMeta.type !== "line") {
        setIsTooltipVisible(false);
        return;
      }

      const position = point.element; // Get the position of the closest point

      // Calculate the position of the tooltip
      const canvasRect = chart.canvas.getBoundingClientRect();
      const parentRect = chart.canvas.parentNode.getBoundingClientRect();
      const tooltipX = position.x + canvasRect.left - parentRect.left;
      const tooltipY = position.y + canvasRect.top - parentRect.top;

      tooltipPosition.current = { x: tooltipX, y: tooltipY };

      const datasets = chart.data.datasets;
      tooltipData.current = {
        income: datasets[0]?.data[dataIndex] || 0,
        expenses: datasets[1]?.data[dataIndex] || 0,
        profit: datasets[2]?.data[dataIndex] || 0,
      };

      setIsTooltipVisible(true);
    };

    const pointHitRadiusBreak = isMobile ? 5 : 30;

    const data = {
      labels: metricGraphData?.months || [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      ],
      datasets: [
        {
          type: "bar",
          label: "Income",
          backgroundColor: "#458CFF",
          hoverBackgroundColor: "#458CFF",
          borderRadius: { topLeft: 10, topRight: 10 },
          data: metricGraphData?.income || Array(12).fill(0),
          yAxisID: "y",
          order: 2,
        },
        {
          type: "bar",
          label: "Expenses",
          backgroundColor: "#FF5A5C",
          hoverBackgroundColor: "#FF5A5C",
          borderRadius: { topLeft: 10, topRight: 10 },
          data: metricGraphData?.expense || Array(12).fill(0),
          yAxisID: "y",
          order: 3,
        },
        {
          type: "line",
          label: "Profit",
          borderColor: "#00D26C",
          data: metricGraphData?.profit || Array(12).fill(0),
          yAxisID: "y",
          tension: 0.1,
          fill: false,
          order: 1,
          pointBackgroundColor: "#00D26C", // Default point background color
          pointBorderColor: "#00D26C", // Default point border color
          pointBorderWidth: 1, // Default point border width
          borderWidth: 7,
          pointHoverBackgroundColor: "#00D26C", // Point background color on hover
          pointHoverBorderColor: "white", // Point border color on hover
          pointHoverBorderWidth: 2, // Thicker border on hover
          pointHitRadius: pointHitRadiusBreak, // Large point hit radius
        },
      ],
    };

    const options = {
      interaction: {
        intersect: true,
        mode: 'nearest'
    },
    hover: {
      intersect: true,
      mode: 'nearest'
  },

      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              if (value === 0) {
                return "0";
              }
              if (Math.abs(value) >= 10000) {
                return (value / 1000).toFixed(0) + "K";
              }
              if (Math.abs(value) > 1000) {
                return value.toFixed(0);
              }
              return "$" + value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            },
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: (context) => createTooltip(context.tooltip),
        },
        legend: {
          display: false,
        },
      },
    };

    const chartInstance = new Chart(ctx, {
      type: "bar",
      data,
      options,
    });

    return () => chartInstance.destroy();
  }, [metricGraphData, isMobile]);

  return (
    <Box position="relative" width="100%" height="100%">
      <canvas id="myChart" style={{ width: "100%", height: "100%" }} />
      {isTooltipVisible && tooltipData.current && (
        <Box
          position="absolute"
          top={`${tooltipPosition.current.y}px`}
          left={`${tooltipPosition.current.x}px`}
          transform="translate(-50%, -100%)"
          pointerEvents="none"
        >
          <ChakraTooltip
            backgroundColor={"white"}
            borderRadius={"10px"}
            boxShadow={"lg"}
            isOpen
            hasArrow
            placement="top"
            label={
              <>
                <Box color={"black"}>
                  <HStack p={"6px 5px 0px"}>
                    <Box>
                      <Box
                        backgroundColor={"#458CFF"}
                        height={"8px"}
                        width={"20px"}
                        borderRadius={"5px"}
                      ></Box>
                    </Box>
                    <Text textAlign={"left"}>Income:</Text>
                    <Spacer />
                    <Spacer />
                    <Text textAlign={"right"}>
                      {customCurrencyFormat(tooltipData.current.income)}
                    </Text>
                  </HStack>
                </Box>
                <Box color="black">
                  <HStack
                    p={"6px 5px"}
                    borderBottom={"1px solid"}
                    borderColor={"gray.300"}
                  >
                    <Box>
                      <Box
                        backgroundColor={"#FF5A5C"}
                        height={"8px"}
                        width={"20px"}
                        borderRadius={"5px"}
                      ></Box>
                    </Box>
                    <Text textAlign={"left"}>Expenses:</Text>
                    <Spacer />
                    <Spacer />
                    <Text textAlign={"right"}>
                      {customCurrencyFormat(tooltipData.current.expenses)}
                    </Text>
                  </HStack>
                </Box>
                <Box color={"black"}>
                  <HStack p={"6px 5px"}>
                    <Box>
                      <Box
                        backgroundColor={"#00D26C"}
                        height={"4px"}
                        width={"20px"}
                        borderRadius={"5px"}
                      ></Box>
                    </Box>
                    <Text textAlign={"left"}>Profit:</Text>
                    <Spacer />
                    <Spacer />
                    <Text textAlign={"right"}>
                      {customCurrencyFormat(tooltipData.current.profit)}
                    </Text>
                  </HStack>
                </Box>
              </>
            }
          >
            <Box />
          </ChakraTooltip>
        </Box>
      )}
    </Box>
  );
};

export default MetricGraph;
