import {
  Box,
  Card,
  CircularProgress,
  CircularProgressLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { customCurrencyFormat } from "../../utils";
import React, { useState } from "react";
import { Circle } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const ProfitCard = ({
  profit = "0.00",
  profitPercentage = "0.00",
  data = {},
  perfectPL = "0.00",
  color = "#458CFF",
  status = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Card
      width="300px"
      height="300px"
      borderRadius="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow={"xl"}
    >
      <StatusModal isOpen={isModalOpen} toggle={toggle} color={color} data={data} />
      <CircularProgress
        value={100}
        size="320px" // Adjusted size to fit within the card better
        thickness="7px"
        color={color}
      >
        <CircularProgressLabel>
          <Box textAlign="center">
            <Box
              fontSize="x-large"
              fontWeight="bold"
              marginBottom={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              Profit
              {status && (
                <Circle
                  cursor="pointer"
                  fontSize="large"
                  size="20px"
                  bg="white"
                  color="black"
                  marginLeft={1}
                  onClick={openModal}
                >
                  <InfoOutlineIcon />
                </Circle>
              )}
            </Box>
          </Box>
          <Box>
            <Text
              fontSize="clamp(32px, calc(3cqw - 0.11vw), 44px)"
              fontWeight="bold"
              marginBottom={1}
              color={color}
            >
              {profit}
            </Text>
            <Text fontSize="lg" fontWeight="bold" marginBottom={1}>
              {profitPercentage}
            </Text>
            <Text fontSize="md" fontWeight="bold" marginBottom={1}>
              Perfect P&L: {perfectPL}
            </Text>
          </Box>
        </CircularProgressLabel>
      </CircularProgress>
    </Card>
  );
};

export default ProfitCard;

const StatusModal = ({ isOpen, toggle, color, data }) => {
  return (
    <Modal isOpen={isOpen} onClose={toggle} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW={1000}
        width={"90%"}
        padding={"20px 0"}
        borderRadius={"15px"}
      >
        <ModalHeader
          textAlign={"center"}
          fontSize="x-large"
          fontWeight="bold"
          paddingTop={"8px"}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text>Business Status</Text>
            <Box
              border="1px"
              borderColor={"#536DEC"}
              width="95px"
              marginTop="4px"
            />
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexWrap={"wrap"}
          justifyContent="center"
          overflowY="auto"
          padding={0}
          height={"100%"}
        >
          <Box textAlign={"center"}>
            <Card
              boxShadow={"xl"}
              margin={6}
              marginBottom={3}
              width="200px"
              height="200px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity={color === "#68BA65" ? "100%" : "70%"}
              borderWidth={color === "#68BA65" ? "3px" : "0px"}
              borderColor={color === "#68BA65" ? "#536DEC" : "black"}
            >
              <CircularProgress
                value={100}
                size="210px"
                thickness="6px"
                color="#68BA65"
              >
                <CircularProgressLabel>
                  <Box textAlign="center">
                    <Text
                      fontSize="large"
                      fontWeight="bold"
                      marginBottom={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Positive
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="35px"
                      fontWeight="bold"
                      marginBottom={1}
                      color="#68BA65"
                    >
                      {customCurrencyFormat(data.positive_value)}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold" marginBottom={1}>
                      30%+
                    </Text>
                  </Box>
                </CircularProgressLabel>
              </CircularProgress>
            </Card>
            {color === "#68BA65" ? <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>Your Current Status</Text> : <></>}
          </Box>

          <Box textAlign={"center"}>
            <Card
              boxShadow={"xl"}
              margin={6}
              marginBottom={3}
              width="200px"
              height="200px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity={color === "#458CFF" ? "100%" : "70%"}
              borderWidth={color === "#458CFF" ? "3px" : "0px"}
              borderColor={color === "#458CFF" ? "#536DEC" : "black"}
            >
              <CircularProgress
                value={100}
                size="210px"
                thickness="6px"
                color="#458CFF"
              >
                <CircularProgressLabel>
                  <Box textAlign="center">
                    <Text
                      fontSize="large"
                      fontWeight="bold"
                      marginBottom={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Neutral
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="35px"
                      fontWeight="bold"
                      marginBottom={1}
                      color="#458CFF"
                    >
                      {customCurrencyFormat(data.neutral_value)}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold" marginBottom={1}>
                      15%+
                    </Text>
                  </Box>
                </CircularProgressLabel>
              </CircularProgress>
            </Card>
            {color === "#458CFF" ? <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>Your Current Status</Text> : <></>}
          </Box>

          <Box textAlign={"center"}>
            <Card
              boxShadow={"xl"}
              margin={6}
              marginBottom={3}
              width="200px"
              height="200px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity={color === "#F1B20E" ? "100%" : "70%"}
              borderWidth={color === "#F1B20E" ? "3px" : "0px"}
              borderColor={color === "#F1B20E" ? "#536DEC" : "black"}
            >
              <CircularProgress
                value={100}
                size="210px"
                thickness="6px"
                color="#F1B20E"
              >
                <CircularProgressLabel>
                  <Box textAlign="center">
                    <Text
                      fontSize="large"
                      fontWeight="bold"
                      marginBottom={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Warning
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="35px"
                      fontWeight="bold"
                      marginBottom={1}
                      color="#F1B20E"
                    >
                      {customCurrencyFormat(data.warning_value)}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold" marginBottom={1}>
                      5%+
                    </Text>
                  </Box>
                </CircularProgressLabel>
              </CircularProgress>
            </Card>
            {color === "#F1B20E" ? <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>Your Current Status</Text> : <></>}
          </Box>

          <Box textAlign={"center"}>
            <Card
              boxShadow={"xl"}
              margin={6}
              marginBottom={3}
              width="200px"
              height="200px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity={color === "#FF5E5E" ? "100%" : "70%"}
              borderWidth={color === "#FF5E5E" ? "3px" : "0px"}
              borderColor={color === "#FF5E5E" ? "#536DEC" : "black"}
            >
              <CircularProgress
                value={100}
                size="210px"
                thickness="6px"
                color="#FF5E5E"
              >
                <CircularProgressLabel>
                  <Box textAlign="center">
                    <Text
                      fontSize="large"
                      fontWeight="bold"
                      marginBottom={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      Caution
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      fontSize="35px"
                      fontWeight="bold"
                      marginBottom={1}
                      color="#FF5E5E"
                    >
                      {customCurrencyFormat(data.caution_value)}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold" marginBottom={1}>
                      5%-
                    </Text>
                  </Box>
                </CircularProgressLabel>
              </CircularProgress>
            </Card>
            {color === "#FF5E5E" ? <Text color={"#536DEC"} fontSize={"large"} fontWeight={1000}>Your Current Status</Text> : <></>}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
